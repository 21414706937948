<template>
  <div v-if="usedMissionData" class="mission-overview">
    <!-- <h1> {{ $t(relevantHeaderTextKeys.title) }} </h1>
    <p> {{ $t(relevantHeaderTextKeys.subtitle) }} </p> -->
    <div class="mission-overview-header-section" :class="{'mission-title-left': prevWeek || nextWeek}">
      <p v-if="currentDay !== null" class="day-text">{{ `Dag ${usedMissionData.dayOfMM}` }}</p>
      <h1 class="mission-title">{{ usedMissionData.name }}</h1>
      <p class="mission-description">{{ usedMissionData.description }}</p>
    </div>

    <div class="mission-content">
      <MissionCenterSection
        v-if="!prevWeek"
        :title="texts.title"
        :subtitle="texts.subtitle"
        :button-text="texts.button"
        :day-number="currentDay"
        :center-image-src="usedMissionData.playedCoverMediaFile"
        :button-route="texts.route"
        :joker-id="usedMissionData ? usedMissionData.jokerId : null"
        :style-preset="nextWeek ? 'blue' : 'purple'"
        :use-bottom-background-part="thisWeek"
        :modal="modalOptions"
      />

      <div v-if="(prevWeek || (nextWeek && showRanking)) && classId !== -1" class="right-section-container">
        <MissionCountdown v-if="prevWeek" :start-time="startTime"/>
        <MissionEndScore v-else-if="nextWeek && showRanking" :show-ranking="showRanking" :end-position="endPosition" :collected-bits="bitsCollected" />
      </div>
    </div>
  </div>
</template>

<script>
import { BASIC_MODAL_CONTENT } from '@/models/basicModal/BasicModalContent'
import { MODAL_NAMES } from '@/utils/helpers/basicModal/ModalController'
import MissionCountdown from '@/components/elements/missions/MissionCountdown'
import MissionCenterSection from '@/components/elements/missions/MissionCenterSection'
import MissionEndScore from './MissionEndScore'
import { computed } from '@vue/reactivity'
import { useI18n } from 'vue-i18n'
import { ACTIVE_WEEK_STATUS, PASSED_WEEK_STATUS, UPCOMING_WEEK_STATUS, PASSED_PRE_RANKING_STATUS } from '@/utils/data/weekStatus'
import extraLogo from '@/assets/img/img_logo_extra.svg'

export default {
  name: 'OverviewMission',
  components: {
    MissionCountdown,
    MissionCenterSection,
    MissionEndScore,
  },
  props: {
    weekStatus: {
      type: String,
      required: false,
      default: 'pre'
    },
    endPosition: {
      type: Number,
      required: false,
      default: 0
    },
    bitsCollected: {
      type: Number,
      required: false,
      default: 0
    },
    startTime: {
      type: Number,
      required: false,
      default: 1627284505
    },
    currentDay: {
      type: Number,
      required: false,
      default: null
    },
    missionData: {
      type: Object,
      required: false,
      default: null
    },
    classId: {
      type: Number,
      required: true
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    professional: {
      type: Boolean,
      default: false
    },
    allMissionsCompleted: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props) {
    const { t } = useI18n()
    const thisWeek = props.weekStatus === ACTIVE_WEEK_STATUS
    const prevWeek = props.weekStatus === UPCOMING_WEEK_STATUS
    const nextWeek = props.weekStatus === PASSED_WEEK_STATUS || props.weekStatus === PASSED_PRE_RANKING_STATUS
    const showRanking = props.weekStatus === PASSED_WEEK_STATUS
    // const currentAuthToken = LocalDataHandler.getAccessToken()
    const texts = computed(() => {
      switch (props.weekStatus) {
        case UPCOMING_WEEK_STATUS:
          return {
            subtitle: t('OVERVIEW.PRE_WEEK_HEADER.SUBTITLE'),
            title: t('OVERVIEW.PRE_WEEK_HEADER.TITLE'),
            button: t('OVERVIEW.DISCOVER_EXTRA'),
            route: `${process.env.VUE_APP_ROOT_GAME}/extra-games`
          }
        case ACTIVE_WEEK_STATUS:
          return {
            subtitle: props.missionData ? props.missionData.description : '',
            title: props.missionData ? props.missionData.name : '',
            button: t('OVERVIEW.START_MISSION'),
            route: `${process.env.VUE_APP_ROOT_GAME}/selecteer-missie/${props.classId}`
          }
        case PASSED_WEEK_STATUS:
          return {
            subtitle: t('OVERVIEW.POST_WEEK_HEADER.SUBTITLE'),
            title: t('OVERVIEW.POST_WEEK_HEADER.TITLE'),
            button: t('OVERVIEW.SHOW_CLASS_CONV'),
            route: `${process.env.VUE_APP_ROOT_GAME}/klasgesprek`
          }
        case PASSED_PRE_RANKING_STATUS:
          return {
            subtitle: t('OVERVIEW.POST_WEEK_HEADER.SUBTITLE'),
            title: t('OVERVIEW.POST_WEEK_HEADER.TITLE'),
            button: t('OVERVIEW.SHOW_CLASS_CONV'),
            route: `${process.env.VUE_APP_ROOT_GAME}/klasgesprek`
          }
      }
    })

    const modalOptions = computed(() => {
      if (thisWeek && !props.professional) {
        return {
          modalOptions: {
            modalContent: props.classId === -1 ? BASIC_MODAL_CONTENT.START_MISSION_WITHOUT_CLASS : BASIC_MODAL_CONTENT.START_MISSION_WITH_CLASS(props.className),
            cancelCallback: null,
            executeCallback: () => {
              window.location.href = texts.value.route
              // store.commit(RESET_BASIC_MODAL_DATA)
            }
          },
          activeModal: MODAL_NAMES.START_MISSION_WITH_CLASS
        }
      }
      return null
    })

    const conversationMissionData = {
      activeCoverMediaFile: require('../../../assets/img/img_gesprek.png'),
      playedCoverMediaFile: require('../../../assets/img/img_gesprek.png'),
      description: '',
      id: null,
      name: 'Begin een nieuw klassengesprek'
    }

    const extraGamesData = {
      activeCoverMediaFile: extraLogo,
      playedCoverMediaFile: extraLogo,
      description: '',
      id: null,
      name: 'WERK AAN JULLIE MEDIAWIJSHEID'
    }

    const usedMissionData = computed(() => {
      if (props.missionData) {
        return props.missionData
      } else if (nextWeek) {
        return conversationMissionData
      } else if (prevWeek) {
        return extraGamesData
      } else {
        return null
      }
    })

    const relevantHeaderTextKeys = computed(() => {
      if (prevWeek) {
        return {
          title: 'OVERVIEW.MISSION.PRE_WEEK.TITLE',
          subtitle: 'OVERVIEW.MISSION.PRE_WEEK.SUBTITLE'
        }
      } else if (thisWeek && props.currentDay === 1) {
        return {
          title: 'OVERVIEW.MISSION.DURING_WEEK_FIRST_MISSION.TITLE',
          subtitle: 'OVERVIEW.MISSION.DURING_WEEK_FIRST_MISSION.SUBTITLE'
        }
      } else if (thisWeek && props.allMissionsCompleted) {
        return {
          title: 'OVERVIEW.MISSION.DURING_WEEK_USER_FINISHED_ALL_MISSION.TITLE',
          subtitle: 'OVERVIEW.MISSION.DURING_WEEK_USER_FINISHED_ALL_MISSION.SUBTITLE'
        }
      } else if (thisWeek && props.currentDay > 1) {
        return {
          title: 'OVERVIEW.MISSION.DURING_WEEK_OTHER_MISSIONS.TITLE',
          subtitle: 'OVERVIEW.MISSION.DURING_WEEK_OTHER_MISSIONS.SUBTITLE'
        }
      } else if (nextWeek) {
        return {
          title: 'OVERVIEW.MISSION.POST_WEEK_RESULTS.TITLE',
          subtitle: 'OVERVIEW.MISSION.POST_WEEK_RESULTS.SUBTITLE'
        }
      } else {
        return {
          title: '',
          subtitle: ''
        }
      }
    })

    return {
      thisWeek,
      prevWeek,
      nextWeek,
      texts,
      relevantHeaderTextKeys,
      showRanking,
      usedMissionData,
      modalOptions
    }
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  },
  // computed: {
  //   countdownTime() {
  //     const currentTime = (Date.now() / 1000)
  //     // get total seconds between the times
  //     var delta = Math.abs(this.startTime - currentTime)
  //
  //     // calculate (and subtract) whole days
  //     const days = Math.floor(delta / 86400)
  //     delta -= days * 86400
  //
  //     // calculate (and subtract) whole hours
  //     const hours = Math.floor(delta / 3600) % 24
  //     delta -= hours * 3600
  //
  //     // calculate (and subtract) whole minutes
  //     const minutes = Math.floor(delta / 60) % 60
  //     delta -= minutes * 60
  //
  //     // what's left is seconds
  //     const seconds = Math.floor(delta % 60) // in theory the modulus is not required
  //
  //     return {
  //       days: days,
  //       hours: hours,
  //       minutes: minutes,
  //       seconds: seconds
  //     }
  //   }
  // },
  created() {
    //this.calculatedTimes()
    //setInterval(this.calculatedTimes, 1000)
  },
  methods: {
    calculatedTimes() {
      const currentTime = (Date.now() / 1000)
      // get total seconds between the times
      let delta = Math.abs(this.startTime - currentTime)

      // calculate (and subtract) whole days
      this.days = Math.floor(delta / 86400)
      delta -= this.days * 86400

      // calculate (and subtract) whole hours
      this.hours = Math.floor(delta / 3600) % 24
      delta -= this.hours * 3600

      // calculate (and subtract) whole minutes
      this.minutes = Math.floor(delta / 60) % 60
      delta -= this.minutes * 60

      // what's left is seconds
      this.seconds = Math.floor(delta % 60) // in theory the modulus is not required
    }
  },
  template: 'OverviewMission'

}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.mission-overview {
  padding: rem(28) rem(40) rem(40) rem(64);
  background: linear-gradient(270deg, #484B90 0%, #413772 100%);
  display: flex;
  flex-direction: column;
  color: white;
  border-radius: rem(15);
  margin-bottom: rem(32);
}

.mission-overview-header-section {
  //height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  &.mission-title-left {
    align-items: start;

    .mission-title {
      text-align: left;
    }
  }

  .day-text {
    background-color: #4D4C89;
    font-weight: 500;
    border-radius: 17.5px;
    text-transform: uppercase;
    padding: rem(8) rem(12);
  }

  .mission-title {
    font-size: rem(28);
    text-transform: uppercase;
    text-align: center;
    overflow-wrap: break-word;
    letter-spacing: 0;
    line-height: rem(40);
    padding: 0.5rem 0;
  }
}

.mission-content {
  display: flex;
}

.right-section-container{
  width: rem(364);
  margin-left: rem(48);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
