<template>
  <div class="daily-bits-container" :style="cssVars">
    <div v-if="newBits" class="new-bits-container">
      <div class="coin-container">
        <img class="coin first-coin" src="@/assets/icons/icn_bits.svg" alt="">
        <img class="coin second-coin" src="@/assets/icons/icn_bits.svg" alt="">
      </div>
      <div class="new-bits">+{{ newBits }}</div>
    </div>
    <div
      v-for="(day, index) in bitsEarned"
      :key="day.date"
      class="day-container"
      :class="[
          {'day-light': currentDay >= index + 1},
          {'not-current-day': currentDay !== index + 1 || progressBar === 'none'}, 
          {'current-day': currentDay === index + 1 && progressBar !== 'none'},
        ]"
      >

      <!-- Display current date with progress bar-->
      <div v-if="currentDay === index + 1 && progressBar === 'bar'" class="day-content">
        <p class="day">{{ day.day }}</p>

        <div class="progress-bar">
          <BitsBar class="progress-bar" :percentage="progress"/>
          <img v-if="showLightbulb" class="bulb-off bulb-icon " :src="bulbIcons.off" alt="">
          <img v-if="showLightbulb" class=" bulb-on bulb-icon" :src="bulbIcons.on" alt="">
        </div>

        <div class="progress-bar-text-container">
          <i18n-t keypath="OVERVIEW.BITS_EARNED" tag="p" class="bits-earned">
            <span>{{ day.val }}</span>
          </i18n-t>
          <p class="bits-earned">{{ maxBits }} bits totaal</p>
        </div>
      </div>

      <!-- Display current date with maximum amount number -->
      <div v-if="currentDay === index + 1 && progressBar === 'number'" class="day-content">
        <p class="day">{{ day.day }}</p>
        <div class="bits-container">
          <img v-if="currentDay >= index + 1" class="bit-icon" :src="require('@/assets/icons/icn_bits.svg')">
          <div v-else class="bit-icon temp-off-bit"/>
          <p class="bits">{{ day.val }} / {{ maxBits }}</p>
        </div>        
      </div>

      <!-- Display most basic current day -->
      <div v-if="currentDay === index + 1 && progressBar === 'none'" class="day-content">
        <p class="day">{{ day.day }}</p>
        <div class="bits-container">
          <img v-if="currentDay >= index + 1" class="bit-icon" :src="require('@/assets/icons/icn_bits.svg')">
          <div v-else class="bit-icon temp-off-bit"/>
          <p class="bits">{{ day.val }}</p>
        </div>
      </div>
      
      <!-- Display other blocks -->
      <div v-if="currentDay !== index + 1" class="day-content">
        <p class="day">{{ day.day }}</p>
        <div class="bits-container">
          <img v-if="currentDay >= index + 1" class="bit-icon" :src="require('@/assets/icons/icn_bits.svg')">
          <div v-else class="bit-icon temp-off-bit"/>
          <p class="bits">{{ day.val }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core'
import BitsBar from '../bits/bitsBar.vue'

import { nl } from 'date-fns/locale'
import { format, parseISO } from 'date-fns'

export default {
  name: 'DailyBitsEarned',
  components: {
    BitsBar
  },
  props: {
    bitsPerDay: {
      type: Array,
      required: true
    },
    maxBits: {
      type: Number,
      required: false,
      default: 0
    },
    currentDay: {
      type: Number,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
    newBits: {
      type: Number,
      required: false,
      default: 0
    },
    showLightbulb: {
      type: Boolean,
      required: false,
      default: true
    },
    progressBar: {
      type: String,
      required: false,
      default: 'none'
    },
    styleOptions: {
      type: Object,
      required: false,
      default: () => ({
        borderRadius: '22px',
        maxWidth: '100%',
        maxHeight: '88px',
        wrap: 'nowrap'
      })
    },
  },
  setup(props) {
    const bulbIcons = {
      on: require('@/assets/img/elements_icons_bulb_bulb-on.png'),
      off: require('@/assets/img/elements_icons_bulb_bulb-off.png'),
    }

    const progress = props.bitsPerDay[props.currentDay - 1] / props.maxBits

    const cssVars = computed(() => {
      return {
        '--progress': `${progress * 100}%`,
        '--on-bulb-opacity': `${animating.value ? 0 : progress}`,
        '--off-bulb-opacity': '1',
        '--border-radius': props.styleOptions.borderRadius,
        '--wrap': props.styleOptions.wrap,
        '--max-width': props.styleOptions.maxWidth,
        '--max-height': props.styleOptions.maxHeight,
      }
    })

    const bitsEarned = ref([])
    function formatBitsEarned() {
      const current = new Date(props.startDate)

      const bitsEarnedFormatted = []
      props.bitsPerDay.forEach((val, i) => {
        bitsEarnedFormatted.push({
          date: current,
          val,
          day: format(
            new Date(current.valueOf()),
            'EEEEEE.',
            { locale: nl }
          )
        })
        current.setDate(current.getDate() + 1)
      })
      bitsEarned.value = bitsEarnedFormatted
    }

    formatBitsEarned()

    const animating = ref(true)
    const animationTime = 2000
    onMounted(() => {
      setTimeout(() => { animating.value = false }, animationTime)
    })

    return {
      cssVars,
      bulbIcons,
      animating,
      progress,
      bitsEarned,
      containerHeight: props.height,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.daily-bits-container {
  width: 100%;
  display: flex;
  flex-wrap: var(--wrap);

  .day-container {
    height: rem(88);
    max-height: var(--max-height);
    max-width: var(--max-width);
    margin-top: rem(8);
    border-radius: var(--border-radius);
    background-color: #4A3C6B;
    color: #2C2148;

    .day {
      margin-bottom: rem(10);
    }

    .bits {
      margin-left: rem(4);
    }

    &:not(:last-child) {
      margin-right: rem(8);
    }
  }

  .day-light {
    color: #FFF;
    background-color: #4D4C89;
  }

  .current-day {
    display: flex;
    align-items: center;
    width: rem(552);
    padding-left: rem(45);
  }

  .not-current-day {
    width: rem(92);

    .day-content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .bits-container {
    display: flex;
    font-weight: 600;
  }

  .bit-icon {
    height: rem(19);
    width: rem(19);
  }

  .temp-off-bit {
    border-radius: 50%;
    background: #2C2148;
  }

  .day {
    text-transform: capitalize;
  }
}

.extra-bits-progressbar {
  width: rem(450);
  background: linear-gradient(133.68deg, #413873 0%, #4A4C90 100%);
  padding: rem(15) rem(45) rem(15) rem(30);
  border-radius: rem(20) rem(20) rem(0) rem(0);
  margin: 0 auto;
  position: relative;
}

.bits-earned {
  font-weight: 500;
  font-size: rem(15);
}

.progress-bar-text-container {
  display: flex;
  justify-content: space-between;
}

.progress-bar {
  width: rem(453);
  height: rem(26);
  position: relative;
}
.bulb-icon {
  position: absolute;
  right: 0;
  transition: opacity 2s ease;
  transform: translate(50%, -95%);
}

.bulb-on{
  opacity: var(--on-bulb-opacity);
}

.bulb-off{
  opacity: var(--off-bulb-opacity);
    transform: translate(50%, -98%);

}

.new-bits-container {
  position: absolute;
  display: flex;
  gap: rem(10);
  align-items: flex-end;
  animation: bitsAwardedAnimation 5s;
  animation-iteration-count: 1;
  left: rem(620);
  bottom: rem(32);
  opacity: 0;

  @keyframes bitsAwardedAnimation {
    0% {
      opacity:0;
      transform: translate3d(0, 0, 0);
    }
    30% {
      opacity:1;
    }
    50% {
      opacity:1;
    }
    100% {
      opacity:0;
      transform: translate3d(-5px, -40px, 0);
    }
  }

  $coin-width: 50px;

  .coin-container {
    display: flex;
    flex-direction: column;

    .coin {
      height: $coin-width;
      width: $coin-width;
    }

    .first-coin {
      transform: rotateY(50deg);
    }

    .second-coin {
      transform: rotate(45deg) rotateY(30deg);
    }
  }

  .new-bits {
    color: black;
    font-size: rem(38);
    font-weight: bold;
    letter-spacing: 0;
    text-align: right;
    line-height: rem(38);
    transform: translateY(-16px);
  }
}
</style>
