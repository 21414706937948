<template>
  <div class="demo-mission-wrapper" :style="`background-image: url('${require('../../../assets/icons/img_demo_bg.svg')}')`" >
    <div class="title-above ">  {{ $t('OVERVIEW.DEMO_MISSION.TITLE_ABOVE') }}   </div>
    <div class="title page-title page-section-title"> {{ $t('OVERVIEW.DEMO_MISSION.TITLE') }} </div>
    <div class="page-description page-section-text"> {{ $t('OVERVIEW.DEMO_MISSION.DESCRIPTION_PRE_DEMO_AVAILABLE') }} </div>
    <!-- <BasicButton class="start-button" :button-text="$t('OVERVIEW.DEMO_MISSION.START_BUTTON')" @click="startDemoMission"/> -->
<!--    <div class="completed-demo-mission">-->
<!--      <p>{{ $t('OVERVIEW.DEMO_MISSION.COMPLETED') }}</p>-->
<!--    </div>-->
  </div>
</template>

<script>

import BasicButton from '@/components/elements/basic/BasicButton'
import ApiHandler from '../../../utils/handlers/ApiHandler'
export default {
  name: 'DemoMissionBanner',
  components: {
    BasicButton
  },
  props: {
    classId: {
      type: Number,
      required: true
    }
  },
  methods: {
    startDemoMission() {
      // todo RESTORE ON 13 SEP OR WRITE CODE
      // todo RESTORE ON 13 SEP OR WRITE CODE
      // todo RESTORE ON 13 SEP OR WRITE CODE
      const formData = new FormData()
      formData.append('classId', this.classId)
      ApiHandler.post('teacher/create-demo-mission-session', formData).then(response => {
        window.open(process.env.VUE_APP_ROOT_GAME + '/demo')
      }).catch(error => {
        //still open even if it errors, we just lose analytics ()
        console.log(error)
        window.open(process.env.VUE_APP_ROOT_GAME + '/demo')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

$max-text-width: rem(341);

.demo-mission-wrapper{
  width: 100%;
  height: rem(448);
  padding: rem(50);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  //display: flex;
  color: white;
  margin-top: rem(20);
  flex-direction: column;
  border-radius: rem(20);
  position: relative;
}

.title-above{
  opacity: 0.5;
  font-size: rem(14);
  margin-top: rem(30);
  margin-bottom: rem(2);
  text-transform: uppercase;
  max-width: $max-text-width;
}

.title{
  max-width: $max-text-width;
  color: white;
  margin-top: rem(10);
  font-size: rem(28);
}

.page-description{
  margin: rem(25) rem(0);
  max-width: $max-text-width;
  color: white;
  line-height: rem(28);
  font-size: rem(18);
}

.start-button{
  width: fit-content;
  padding: rem(10) rem(50);
}

.completed-demo-mission {
  background-color: var(--blue_light_03);
  padding: rem(8);
  border-radius: rem(16);
  position: absolute;
  top: 2rem;
  right: 1rem;

  p {
    font-weight: bold;
    font-size: rem(14);
  }
}

</style>
